/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const Index = {
    async delete(bank_account_id) {
        await axios.delete(`/api/bank_accounts/${bank_account_id}`)
    },
    async list(params) {
        const response = await axios.get('/api/bank_accounts', {responseType: 'json', params})
        return response.data.data
    },
    async show(bank_account_id) {
        const response = await axios.get(`/api/bank_accounts/${bank_account_id}`)

        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/bank_accounts', data, {responseType: 'json'})

        return response.data
    },
    async update(bankAccount) {
        const response = await axios.patch(`/api/bank_accounts/${bankAccount.id}`, bankAccount)

        return response.data.data
    },
}

export default Index