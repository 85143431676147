/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const JournalVoucherExtract = {
    async delete(journal_voucher_extract_id) {
        await axios.delete(`/api/journal_voucher_extracts/${journal_voucher_extract_id}`)
    },
    async list(params) {
        const response = await axios.get('/api/journal_voucher_extracts', {responseType: 'json', params})
        return response.data.data
    },
    async store(data) {
        const response = await axios.post('/api/journal_voucher_extracts', data, {responseType: 'json'})
        return response.data
    },
}

export default JournalVoucherExtract